<template>
  <div>
    <div
      class="row trendbox"
      v-for="(tx,i) in histograms"
      :key="i"
    >
      <StudyTrend :hist="tx" />
    </div>
  </div>
</template>

<script>
import StudyTrend from "@/components/StudyTrend.vue";
import StudySubmitHistogram from "@/classes/StudySubmitHistogram.js";

export default {
  name: "Overview",
  components: {
    StudyTrend,
  },
  data() {
    return {
      histograms: [],
    };
  },
  methods: {
    // sort the submit history data into individual histograms for each study site
    // each 'submitHistory' record contains:
    //    pk            - partition key = 'histogram'
    //    sk            - sort key = study site slug + '#' + a number to make the key unique
    //    studySite     - the name of the study site
    //    relativeDay
    //    submitDate    - the date (string) that studies were submitted
    //    studies       - the number of studies submitted on 'submitDate'
    createHistograms() {
      this.histograms = [];
      var siteList = [];
      var k = 0;
      for (var i = 0; i < this.submitHistory.length; i++) {
        k = siteList.indexOf(this.submitHistory[i].studySite);
        if (k == -1) {
          siteList.push(this.submitHistory[i].studySite);
        }
      }

      var hy = [];
      for (i = 0; i < siteList.length; i++) {
        for (k = 0; k < this.submitHistory.length; k++) {
          if (siteList[i] == this.submitHistory[k].studySite) {
            hy.push(this.submitHistory[k]);
          }
        }
        this.histograms.push(new StudySubmitHistogram(hy));
        hy = [];
      }
    },
  },
  computed: {
    submitHistory() {
      return this.$store.getters.studySubmitHistory;
    },
  },
  watch: {
    submitHistory: function (newh, oldh) {
      if (!oldh || (newh.length > 0 && newh.length != oldh.length)) {
        this.createHistograms();
      }
    },
  },
  created() {
    this.$store.dispatch("getSubmitHistory");
  },
  mounted() {
    if (this.submitHistory && this.submitHistory.length > 0) {
      this.createHistograms();
    }
  },
};
</script>

<style>
.trendbox {
  margin: 16px 0 0 32px; /*  top  right  bottom  left   */
  width: 560px;
  display: inline-block;
}
</style>
