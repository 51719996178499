export default class StudySubmitHistogram {

    histdata = [];
    submitDate = [];
    studies = [];
    relativeDay = [];
    studySite = "none";
    siteslug = "none";
    totalStudies = 0;
    recentStudies = 0;

    constructor(histdata) {

        this.histdata = histdata;
        if (histdata && histdata.length > 0) {

            // sort the histogram data according to the relativeDay parameter
            this.histdata = histdata.sort(function (a, b) {
                return b.relativeDay - a.relativeDay
            });

            this.studySite = histdata[0].studySite;
            this.siteslug = 'slugx' + histdata[0].studySite.replace(' ', 'q');

            this.recentStudies = 0;
            this.totalStudies = 0;
            for (var i = 0; i < this.histdata.length; i++) {
                if (i < 31) {       // truncate the histogram at 31 bins
                    this.recentStudies += this.histdata[i].studies;
                    this.submitDate.push(this.histdata[i].submitDate);
                    this.relativeDay.push(this.histdata.relativeDay);
                    this.studies.push(this.histdata[i].studies);
                }
                this.totalStudies += this.histdata[i].studies;
            }
        }
    }

}