<!-- 
    this component renders an object of type 'StudySubmitHistogram'
-->
<template>
  <div class="histbox">
    <div class="summarybox">
      <p class="sitetitle">{{hist.studySite}}</p>
      <a class="statlist">total studies: {{hist.totalStudies}}</a>
      <a class="statlist">last 30 days: {{hist.recentStudies}}</a>
      <a
        class="statlist"
        v-if="selectedBar"
      >{{selectedBar}}: {{selectedStudies}}</a>
    </div>
    <!--  <hr class="rulebox">  -->
    <div :id="hist.siteslug">
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import StudySubmitHistogram from "@/classes/StudySubmitHistogram.js";

export default {
  name: "StudyTrend",
  props: {
    hist: StudySubmitHistogram,
  },
  data() {
    return {
      svg: null,
      graph: null,
      graphWidth: null,
      graphHeight: null,
      xAxisGroup: null,
      yAxisGroup: null,
      tooldiv: null,
      xa: null,
      ya: null,
      dims: { width: 560, height: 120 },
      margin: { top: 5, right: 20, bottom: 10, left: 32 },
      selectedBar: null,
      selectedStudies: 0,
    };
  },
  methods: {
    setupHistogram() {
      // create the SVG container for the histogram
      this.svg = d3
        .select("#" + this.hist.siteslug)
        .append("svg")
        .attr("width", this.dims.width)
        .attr("height", this.dims.height);

      this.graphWidth = this.dims.width - this.margin.left - this.margin.right;
      this.graphHeight =
        this.dims.height - this.margin.bottom - this.margin.top;

      this.graph = this.svg
        .append("g")
        .attr(
          "transform",
          `translate(${this.margin.left}, ${this.margin.top})`
        );

      // Define the div for the tooltip
      this.tooldiv = d3
        .select("#" + this.hist.siteslug)
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);

      this.xAxisGroup = this.graph
        .append("g")
        .attr("transform", `translate(0, ${this.graphHeight})`);
      this.yAxisGroup = this.graph.append("g");

      // create the linear scale for the vertical axis
      this.ya = d3.scaleLinear().range([this.graphHeight, 0]); // range of output values (pixels)

      // create the linear scale for the horizontal axis
      this.xa = d3.scaleLinear().range([0, this.graphWidth]);

      this.plotHistogram();
    },
    plotHistogram() {
      const ymax = d3.max(this.hist.studies);
      this.ya.domain([0, ymax]);

      const nbins = this.hist.studies.length;
      this.xa.domain([0, nbins]);
      const binWidth = this.graphWidth / nbins - 2;

      // create and call the axes
      //const xAxis = d3.axisBottom(this.xa);
      const yAxis = d3.axisLeft(this.ya).ticks(2);

      // join the data to some rectangles
      const rects = this.graph.selectAll("rect").data(this.hist.histdata);

      // remove the exit selection
      rects.exit().remove();

      // add attrs to rects already in DOM
      rects
        .attr("width", binWidth)
        .attr("height", (d) => this.graphHeight - this.ya(d.studies))
        .attr("fill", "#C4DD9C")
        .attr("x", (d, i) => this.xa(i))
        .attr("y", (d) => this.ya(d.studies))
        .on("mouseover", (evt, d) => {
          d3.select(evt.currentTarget).attr("fill", "#4B6076");
          this.selectedBar = d.submitDate;
          this.selectedStudies = d.studies;
        })
        .on("mouseout", (evt) => {
          d3.select(evt.currentTarget).attr("fill", "#C4DD9C");
          this.selectedBar = null;
        });

      // append rects and attrs to the enter selection
      rects
        .enter()
        .append("rect")
        .attr("width", binWidth)
        .attr("height", (d) => this.graphHeight - this.ya(d.studies))
        .attr("fill", "#C4DD9C")
        .attr("x", (d, i) => this.xa(i))
        .attr("y", (d) => this.ya(d.studies))
        .on("mouseover", (evt, d) => {
          d3.select(evt.currentTarget).attr("fill", "#4B6076");
          this.selectedBar = d.submitDate;
          this.selectedStudies = d.studies;
        })
        .on("mouseout", (evt) => {
          d3.select(evt.currentTarget).attr("fill", "#C4DD9C");
          this.selectedBar = null;
        });

      //this.xAxisGroup.call(xAxis);
      this.yAxisGroup.call(yAxis);
    },
  },
  created() {},
  mounted() {
    this.setupHistogram();
  },
};
</script>

<style scoped>
.histbox {
  background-color: rgb(233, 233, 233);
  border-radius: 4px;
}
.summarybox {
  background-color: #4b6076;
}
.summarybox .sitetitle {
  font-weight: semi-bold;
  color: white;
  font-size: 24px;
  margin: 10px 0 0 32px;
}
.summarybox .statlist {
  margin: 0 30px 30px 32px; /*  top  right  bottom  left   */
  color: white;
  font-weight: semi-bold;
}
.rulebox {
  margin: 0 12px 5px 32px;
}
.tooltip {
  position: absolute;
  text-align: center;
  width: 60px;
  height: 28px;
  padding: 2px;
  font: 12px sans-serif;
  background: lightsteelblue;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
}
</style>
